import React from "react"

function InstagramIcon({ url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <svg
        width="512"
        height="512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M305 256C305 283.062 283.062 305 256 305C228.938 305 207 283.062 207 256C207 228.938 228.938 207 256 207C283.062 207 305 228.938 305 256Z" />
        <path d="M370.594 169.305C368.238 162.922 364.48 157.145 359.598 152.402C354.855 147.52 349.082 143.762 342.695 141.406C337.516 139.395 329.734 137 315.402 136.348C299.898 135.641 295.25 135.488 256 135.488C216.746 135.488 212.098 135.637 196.598 136.344C182.266 137 174.48 139.395 169.305 141.406C162.918 143.762 157.141 147.52 152.402 152.402C147.52 157.145 143.762 162.918 141.402 169.305C139.391 174.484 136.996 182.27 136.344 196.602C135.637 212.102 135.484 216.75 135.484 256.004C135.484 295.254 135.637 299.902 136.344 315.406C136.996 329.738 139.391 337.52 141.402 342.699C143.762 349.086 147.516 354.859 152.398 359.602C157.141 364.484 162.914 368.242 169.301 370.598C174.48 372.613 182.266 375.008 196.598 375.66C212.098 376.367 216.742 376.516 255.996 376.516C295.254 376.516 299.902 376.367 315.398 375.66C329.73 375.008 337.516 372.613 342.695 370.598C355.516 365.652 365.648 355.52 370.594 342.699C372.605 337.52 375 329.738 375.656 315.406C376.363 299.902 376.512 295.254 376.512 256.004C376.512 216.75 376.363 212.102 375.656 196.602C375.004 182.27 372.609 174.484 370.594 169.305V169.305ZM256 331.484C214.309 331.484 180.512 297.691 180.512 256C180.512 214.309 214.309 180.516 256 180.516C297.688 180.516 331.484 214.309 331.484 256C331.484 297.691 297.688 331.484 256 331.484ZM334.469 195.172C324.727 195.172 316.828 187.273 316.828 177.531C316.828 167.789 324.727 159.891 334.469 159.891C344.211 159.891 352.109 167.789 352.109 177.531C352.105 187.273 344.211 195.172 334.469 195.172Z" />
        <path d="M256 0C114.637 0 0 114.637 0 256C0 397.363 114.637 512 256 512C397.363 512 512 397.363 512 256C512 114.637 397.363 0 256 0ZM402.113 316.605C401.402 332.254 398.914 342.938 395.281 352.289C387.645 372.035 372.035 387.645 352.289 395.281C342.941 398.914 332.254 401.398 316.609 402.113C300.934 402.828 295.926 403 256.004 403C216.078 403 211.074 402.828 195.395 402.113C179.75 401.398 169.062 398.914 159.715 395.281C149.902 391.59 141.02 385.805 133.676 378.324C126.199 370.984 120.414 362.098 116.723 352.289C113.09 342.941 110.602 332.254 109.891 316.609C109.168 300.93 109 295.922 109 256C109 216.078 109.168 211.07 109.887 195.395C110.598 179.746 113.082 169.062 116.715 159.711C120.406 149.902 126.195 141.016 133.676 133.676C141.016 126.195 149.902 120.41 159.711 116.719C169.062 113.086 179.746 110.602 195.395 109.887C211.07 109.172 216.078 109 256 109C295.922 109 300.93 109.172 316.605 109.891C332.254 110.602 342.938 113.086 352.289 116.715C362.098 120.406 370.984 126.195 378.328 133.676C385.805 141.02 391.594 149.902 395.281 159.711C398.918 169.062 401.402 179.746 402.117 195.395C402.832 211.07 403 216.078 403 256C403 295.922 402.832 300.93 402.113 316.605V316.605Z" />
      </svg>
    </a>
  )
}

export default InstagramIcon
